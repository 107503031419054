import React, { useState } from 'react'
import './App.css'
import Auth from './components/Auth'
import Cookies from 'universal-cookie'
import Chat from './components/Chat'
import { signOut } from 'firebase/auth'
import { auth } from './firebase-config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const cookies = new Cookies()
function App() {

  const [isAuth, setIsAuth] = useState(cookies.get("auth-token"))

  const [room, setRoom] = useState(null)

  const roomInputRef = useState(null)

  if (!isAuth) {
    return (
      <>
        <h1> <Auth setIsAuth={setIsAuth} /></h1>
      </>
    )
  }

  const signUserOut = async () => {
    await signOut(auth)
    cookies.remove("auth-token")
    setIsAuth(false)
    setRoom(null)
  }

  return (
    <div>
      {room ? (<div>
        <Chat room={room} />
      </div>)
        : (<div className='room'>
          <h1> Enter Room Name:</h1>
          <input ref={roomInputRef} />
          <button onClick={() => {setRoom(roomInputRef.current.value)}} >Enter Chat</button>
          <button onClick={() => {
            signUserOut()
          }} style={{ backgroundColor: '#7b88a3' }}>Sign out</button>
          <ToastContainer />
        </div>)
      }
    </div>
  )

}

export default App