// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFKiUt4b0ZV4p8gMTH7F8Y0e016hWKtqM",
  authDomain: "chatapp-97d13.firebaseapp.com",
  projectId: "chatapp-97d13",
  storageBucket: "chatapp-97d13.appspot.com",
  messagingSenderId: "116082269166",
  appId: "1:116082269166:web:92361f478900fd58c8ccff"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth=getAuth(app);
export const provider=new  GoogleAuthProvider();
export const db=getFirestore(app)